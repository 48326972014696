import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PageTile from "../layout/hausgeburtPagetiles/pageTileRedHeadline"
import HeaderGraphic from "../images/headerBackgroundHausgeburt.svg"
import HebammeBett from "../images/HebammeBett.svg"
import {StaticImage} from "gatsby-plugin-image"
import MainMenu from "./main-menu"


const Header = ({ data, teaser  }) => (
  <>
  <header>
  <div className="hausgeburt site-header">
    <StaticImage className="hausgeburt-headerBackgroundGraph" src='../images/headerBackgroundHausgeburt.svg' alt="Rote Corporate Vectorgraphic" loading="eager"/>
    
    
    <MainMenu></MainMenu>
    <div className="header-container hausgeburt grid-template-hausgeburt">
    <h1>{data.title}</h1>
    <h2>{data.subtitle}</h2>
    <p>{data.teaserFull}</p>
    <StaticImage objectFit="contain" className="hausgeburtHeaderGraph" src='../images/HebammeBett.svg' alt="Hebamme begleitet Gebärende Schwangere Frau im Bett" loading="eager" placeholder="blurred"/>
    </div>
    
  </div>
  <PageTile outerClass="hausgeburt-header-teaser">
     <div className="teaser-question first"><div className="sign">{teaser[0].sign}</div ><div className="question">{teaser[0].text}</div></div>
     <div className="teaser-question middle"><div className="sign">{teaser[1].sign}</div ><div className="question">{teaser[1].text}</div></div>
     <div className="teaser-question last"><div className="sign">{teaser[2].sign}</div ><div className="question">{teaser[2].text}</div></div>
    </PageTile>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
