import * as React from "react"
import {useEffect, useRef} from "react"
import Layout from "../layout/layoutHausgeburt"
import PageTile from "../layout/hausgeburtPagetiles/pageTileRedHeadline"
import "../styles/stylesloader.scss"
import { StaticImage } from "gatsby-plugin-image"
import LanguageData from "../languageFiles/DE.json";
import Seo from "../seo";


const Hausgeburt = () => {

  const Fact = ({fact, text, src, srcDescription, className}) => (
    <div className={`hausgeburt factContainer ${className}`}>
      <span className="factNumber">{fact}</span>
      <p className="factText" dangerouslySetInnerHTML={{__html: text}}></p>
      <a href={src} target="_blank" rel="noreferrer">{srcDescription}</a>
    </div>
  )

  return (
    
    <Layout>
      <Seo title="Hebamme Hausgeburt Ulm" description="Ich bin Hebamme für Hausgeburten in Ulm / Neu-Ulm. Ich sorge für dich wärend Schwangerschaft, Geburt &amp; Wochenbett. Lerne mich kennen" />
      <PageTile outerClass="">
        <div className="hausgeburt section-title-background-left background-red with-img-bottom">
          <h1 className="section-title">{LanguageData.siteHausgeburt.post_1.title}</h1>
        </div>
        <div className="hausgeburt section-text section-text-right background-none"> 
          <p className="textblock-x-4">{LanguageData.siteHausgeburt.post_1.text}</p>
        </div>
        <StaticImage placeholder="blurred" src="../images/CouplePoolBirth.svg" alt="Schwangere Frau mit Mann in Gebärpool in Ulm / Neu-Ulm" className="hausgeburt article-img-big couplePool"/>
      </PageTile>


      <PageTile outerClass="facts">
        <Fact {...LanguageData.siteHausgeburt.fact_1[0]} className="first"></Fact>
        <Fact {...LanguageData.siteHausgeburt.fact_1[1]} className="second"></Fact>      
      </PageTile>

      <PageTile outerClass="">
      <div className="hausgeburt section-text section-text-left background-none"> 
          <p className="textblock-x-4">{LanguageData.siteHausgeburt.post_2.text}</p>
        </div>
        <div className="hausgeburt section-title-background-right background-red">
          <h1 className="section-title">{LanguageData.siteHausgeburt.post_2.title}</h1>
        </div>
        <StaticImage placeholder="blurred" src="../images/WomanKneesBook.svg" alt="Schwangere Frau schaut in ihren Mutterpass in Ulm / Neu-Ulm" className="hausgeburt article-img-big womanKnees"/>
      </PageTile>

      <PageTile outerClass="">
        <div className="hausgeburt section-title-background-left background-none">
          <h1 className="section-title">{LanguageData.siteHausgeburt.post_3.title}</h1>
        </div>
        <div className="hausgeburt section-text section-text-right section-text-right-red background-red"> 
          <p className="textblock-x-4">{LanguageData.siteHausgeburt.post_3.text}</p>
        </div>
        <StaticImage placeholder="blurred" src="../images/coupleBall.svg" alt="Schwangere Frau mit Mann auf einem Gymnastikball in Ulm / Neu-Ulm" className="hausgeburt article-img-big coupleBall"/>
      </PageTile>

      <PageTile outerClass="">
        <div className="hausgeburt section-title-background-left background-none mobile-left">
          <h1 className="section-title">{LanguageData.siteHausgeburt.post_4.title}</h1>
        </div>
        <div className="hausgeburt section-text section-text-right background-none"> 
          <p className="textblock-x-4">{LanguageData.siteHausgeburt.post_4.text}</p>
        </div>
      </PageTile>

      <PageTile outerClass="facts">
        <Fact {...LanguageData.siteHausgeburt.fact_2[0]} className="first"></Fact>
        <Fact {...LanguageData.siteHausgeburt.fact_2[1]} className="second"></Fact>      
      </PageTile>

      <PageTile outerClass="background-red">
        <div className="hausgeburt section-title-background-left background-red align-top-container no-padding-bottom">
          <h1 className="section-title align-top-box">{LanguageData.siteHausgeburt.post_5.title}</h1>
        </div>
        <div className="hausgeburt section-text section-text-right background-red"> 
          <p className="textblock-x-4">{LanguageData.siteHausgeburt.post_5.text}</p>
        </div>
      </PageTile>

    </Layout>
    
  )
}

export default Hausgeburt
